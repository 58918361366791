import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import api from "../api";
import { message } from "antd";
import { navigate } from "gatsby";

export const AuthContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setToken(userData.jwt);
      setUser(userData?.user);
    }
    setLoading(false);
  }, []);

  async function handleLogout() {
    localStorage.removeItem("userData");
    setUser();
    setToken();
    message.success("Logout bem sucedido");
    navigate("/login");
  }

  async function handleLogin(values, checked) {
    try {
      const response = await api.post("/auth/local", {
        identifier: values.username,
        password: values.password
      });

      setUser(response.data?.user);
      setToken(response.data.jwt);

      if (checked) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }

      navigate("/");
      message.success("Login bem sucedido");
    } catch (error) {
      message.error("Usuário ou senha inválidos");
    }
  }
  return (
    <AuthContext.Provider
      value={{ user, handleLogin, handleLogout, token, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

GlobalContextProvider.propTypes = { children: PropTypes.node };

export default GlobalContextProvider;
